import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import {
  ButtonLink,
  ButtonLinkProps,
  Section,
  SectionProps,
  Wrapper,
} from "components";

import { HeroPattern } from "./types";

import {
  cardHero,
  defaultHero,
  pageHero,
  panelHero,
  heroButtons,
  heroContainer,
} from "./Hero.module.scss";

export interface HeroButtonProps extends Omit<ButtonLinkProps, "label"> {
  label?: Element | ReactNode | string;
}

export const HeroButton: FC<HeroButtonProps> = ({
  children,
  label,
  to,
  ...rest
}) => (
  <ButtonLink {...(rest as ButtonLinkProps)} to={to}>
    {label ? label : children}
  </ButtonLink>
);

export interface HeroProps extends Omit<SectionProps, "is"> {
  buttons?: HeroButtonProps[];
  is?: HeroPattern;
}

export const Hero: FC<HeroProps> = ({
  as = "header",
  buttons,
  caption,
  children,
  color,
  className,
  container,
  is = "page",
  vh,
  ...rest
}) => (
  <Section
    as={as}
    color={color}
    is="hero"
    vh={vh}
    {...(rest as SectionProps)}
    caption={{
      ...caption,
      headingProps: {
        as: "h1",
        ...caption?.headingProps,
      },
      subheadingProps: {
        as: "h2",
        ...caption?.subheadingProps,
      },
    }}
    container={{
      ...container,
      className: clsx(heroContainer, container?.className),
    }}
    className={clsx(
      is === "card" && cardHero,
      is === "default" && defaultHero,
      is === "page" && pageHero,
      is === "panel" && panelHero,
      className
    )}
  >
    {children}
    {buttons?.length && (
      <Wrapper as="div" className={heroButtons}>
        {buttons?.map((button: HeroButtonProps) => (
          <HeroButton key={button.to} {...(button as ButtonLinkProps)}>
            {button?.label}
          </HeroButton>
        ))}
      </Wrapper>
    )}
  </Section>
);
