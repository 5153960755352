import React, { FC } from "react";
import clsx from "clsx";

import { Box, Link, Media, Section, SectionProps, Text } from "components";
import { useAirtableRecentPressQuery } from "graphql";

import {
  recentPressSection,
  recentPressLink,
  recentPressMedia,
  recentPressTitle,
} from "./RecentPress.module.scss";

export interface RecentPressProps extends SectionProps {
  heading?: string;
}

export const RecentPress: FC<RecentPressProps> = ({
  children,
  className,
  heading = "Recent Articles and Podcasts",
}) => {
  const { nodes } = useAirtableRecentPressQuery();

  return (
    <Section
      className={clsx(recentPressSection, className)}
      caption={{ heading }}
    >
      {nodes.map(({ data: { id, image, link, title } }) => (
        <Link
          className={recentPressLink}
          to={link}
          rel="noopener"
          target="_blank"
          key={id}
        >
          {image?.localFiles?.length ? (
            <Media className={recentPressMedia} image={image.localFiles[0]} />
          ) : (
            <Box className={recentPressMedia} />
          )}
          {title && (
            <Text className={recentPressTitle} as="h4" is="subheading">
              {title}
            </Text>
          )}
        </Link>
      ))}
      {children}
    </Section>
  );
};
