import React, { FC } from "react";
import { find } from "lodash";

import {
  Caption,
  Card,
  Media,
  PageSection,
  SplitSectionProps,
  Text,
} from "components";
import { useTheme } from "contexts";
import { useIconsQuery } from "graphql";

import {
  missionCard,
  missionCardIcon,
  missionCardTitle,
} from "./Mission.module.scss";

export interface MissionProps extends SplitSectionProps {
  heading?: string;
}

export const Mission: FC<MissionProps> = ({ children, heading, ...rest }) => {
  const { theme } = useTheme();
  const icons = useIconsQuery();

  return (
    <PageSection
      {...(rest as SplitSectionProps)}
      left={<Caption heading="The Mission" />}
      right={
        <>
          <Card className={missionCard}>
            <Media
              className={missionCardIcon}
              image={
                theme === "dark"
                  ? find(icons, ["name", "networking-icon-dark"])
                  : find(icons, ["name", "networking-icon-light"])
              }
            />
            <Text as="h4" is="subtitle" className={missionCardTitle}>
              Community
            </Text>
            <Text as="p">
              A focus on building vibrant, supportive communities where members
              are connected through shared experiences, goals, and perspectives.
            </Text>
          </Card>
          <Card className={missionCard}>
            <Media
              className={missionCardIcon}
              image={
                theme === "dark"
                  ? find(icons, ["name", "investor-icon-dark"])
                  : find(icons, ["name", "investor-icon-light"])
              }
            />
            <Text as="h4" is="subtitle" className={missionCardTitle}>
              Intention
            </Text>
            <Text as="p">
              Community members define what they need from the company and not
              the other way around.
            </Text>
          </Card>
          <Card className={missionCard}>
            <Media
              className={missionCardIcon}
              image={
                theme === "dark"
                  ? find(icons, ["name", "handshake-icon-dark"])
                  : find(icons, ["name", "handshake-icon-light"])
              }
            />
            <Text as="h4" is="subtitle" className={missionCardTitle}>
              Value
            </Text>
            <Text as="p">
              We create value by addressing real customer need, recognizing that
              people are not commodities.
            </Text>
          </Card>
        </>
      }
    >
      {children}
    </PageSection>
  );
};
