import React, { FC, useState } from "react";
import clsx from "clsx";

import { Section, SectionProps } from "components";
import { Copyright, CopyrightProps } from "containers";
import { ThemeSwitch, useTheme } from "contexts";
import { GatsbyLocation } from "types";

export type LayoutFooterPattern = "page";

export interface LayoutFooterProps extends Omit<SectionProps, "is"> {
  activeClassName?: string;
  copyright?: CopyrightProps;
  isHidden?: boolean;
  is?: LayoutFooterPattern;
  location?: GatsbyLocation;
  showCopyright?: boolean;
}

export const LayoutFooter: FC<LayoutFooterProps> = ({
  as = "footer",
  children,
  className,
  container,
  copyright,
  is = "page",
  isHidden = false,
  location,
  showCopyright = true,
  ...rest
}) => {
  const [switchOn, setSwitchOn] = useState(false);
  const { toggleTheme } = useTheme();

  if (isHidden) return null;

  return (
    <Section
      as={as}
      is="navbar"
      color="primary"
      {...(rest as SectionProps)}
      container={{
        ...container,
        className: clsx(
          "flex flex-row flex-wrap",
          "content-between items-end justify-between",
          container?.className
        ),
      }}
      className={clsx(
        "items-end text-center sm:text-left",
        is === "page" && "pt-12 md:pt-14 xl:pt-16",
        className
      )}
    >
      {children}
      {showCopyright && <Copyright {...copyright} />}
      <ThemeSwitch
        className="mt-4"
        checked={switchOn}
        onChange={(event: any) => {
          setSwitchOn(!switchOn);
          toggleTheme(event);
        }}
      />
    </Section>
  );
};
