import { useStaticQuery, graphql } from "gatsby";

export interface AirtableCompanyData {
  data: {
    description: string;
    id: string;
    name: string;
    website?: string;
  };
}

export interface CompaniesQuery {
  companies: {
    nodes: AirtableCompanyData[];
    totalCount?: number;
  };
}

export const airtableCompanyDataFragment = graphql`
  fragment AirtableCompanyDataFragment on Airtable {
    data {
      # content {
      #   childMdx {
      #     body
      #   }
      #   raw
      # }
      description
      id
      name
      website
    }
  }
`;

export const useAirtableCompaniesQuery = () => {
  const { companies }: CompaniesQuery = useStaticQuery(graphql`
    query AirtableCompaniesQuery {
      companies: allAirtable(
        filter: {
          data: { published: { eq: true } }
          table: { eq: "AuthenTech Companies" }
        }
        sort: { order: ASC, fields: data___name }
      ) {
        nodes {
          ...AirtableCompanyDataFragment
        }
        totalCount
      }
    }
  `);

  return companies;
};
