import React, { FC } from "react";
import clsx from "clsx";

import { Link, LinkProps, ListPrimitive as List, ListProps } from "components";
import { useSiteMetadataQuery } from "graphql";
import { GatsbyLocation } from "types";

import {
  activeFooterMenuLink,
  footerMenu,
  footerMenuLink,
} from "./FooterMenu.module.scss";

export interface FooterMenuProps extends ListProps {
  link?: LinkProps;
  location?: GatsbyLocation;
  nav?: ListProps;
  showNavLinks?: boolean;
}

export const FooterMenu: FC<FooterMenuProps> = ({
  children,
  className,
  link,
  location,
  nav,
  showNavLinks = true,
  ...rest
}) => {
  const { socialMedia } = useSiteMetadataQuery();

  return (
    <List
      as="nav"
      {...(rest as ListProps)}
      className={clsx(footerMenu, className)}
    >
      {children}
      {showNavLinks && (
        <>
          <Link
            activeClassName={activeFooterMenuLink}
            {...(link as LinkProps)}
            className={clsx("md:order-1", footerMenuLink, link?.className)}
            to="/about"
          >
            About
          </Link>
          <Link
            activeClassName={activeFooterMenuLink}
            {...(link as LinkProps)}
            className={clsx("md:order-3", footerMenuLink, link?.className)}
            to="/companies"
          >
            Companies
          </Link>
          {/* <Link
          activeClassName={activeFooterMenuLink}
          {...(link as LinkProps)}
          className={clsx(
            footerMenuLink,
            link?.className
          )}
          to="/resources"
        >
          Resources
        </Link> */}
          <Link
            activeClassName={activeFooterMenuLink}
            {...(link as LinkProps)}
            className={clsx("md:order-2", footerMenuLink, link?.className)}
            to="/join"
          >
            Join AuthenTech
          </Link>
          {socialMedia?.twitter && (
            <Link
              {...(link as LinkProps)}
              className={clsx("md:order-4", footerMenuLink, link?.className)}
              rel="noopener"
              target="_blank"
              to={socialMedia.twitter}
            >
              Follow us on Twitter
            </Link>
          )}
        </>
      )}
    </List>
  );
};
