import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImageProps } from "types";

export interface IconsQuery {
  icons: {
    nodes: GatsbyImageProps[];
  };
}

export const iconFragment = graphql`
  fragment IconFragment on File {
    childImageSharp {
      gatsbyImageData(
        formats: [AUTO, WEBP, AVIF]
        layout: CONSTRAINED
        placeholder: TRACED_SVG
        quality: 90
      )
    }
    id
    name
  }
`;

export const useIconsQuery = () => {
  const {
    icons: { nodes },
  }: IconsQuery = useStaticQuery(
    graphql`
      query IconsQuery {
        icons: allFile(filter: { absolutePath: { regex: "/assets/icons/" } }) {
          nodes {
            ...IconFragment
          }
        }
      }
    `
  );

  return nodes;
};
