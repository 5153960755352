import { useStaticQuery, graphql } from "gatsby";

import { AirtableCompanyData } from "graphql";

export interface RecentCompaniesQuery {
  recentCompanies: {
    nodes: AirtableCompanyData[];
  };
}

export const useAirtableRecentCompaniesQuery = () => {
  const { recentCompanies }: RecentCompaniesQuery = useStaticQuery(graphql`
    query AirtableRecentCompaniesQuery {
      recentCompanies: allAirtable(
        filter: {
          data: { published: { eq: true } }
          table: { eq: "AuthenTech Companies" }
        }
        limit: 50
        sort: { order: DESC, fields: data___createdDate }
      ) {
        nodes {
          ...AirtableCompanyDataFragment
        }
      }
    }
  `);

  return recentCompanies;
};
