import React, { FC } from "react";
import clsx from "clsx";

import { TextPrimitive as Text, TextProps, TextPattern } from "components";
import { BrandColor, BrandPattern } from "containers";
import { useBrandQuery } from "graphql";

import { brandName, brandNameTitle } from "./BrandName.module.scss";

export interface BrandNameProps extends Omit<TextProps, "color"> {
  color?: BrandColor;
  is?: BrandPattern;
  isShort?: boolean;
  text?: TextPattern;
}

export const BrandName: FC<BrandNameProps> = ({
  children,
  className,
  color,
  is,
  isShort,
  text = "subtitle",
  ...rest
}) => {
  const { name, title } = useBrandQuery();

  return (
    <Text
      is={text}
      className={clsx(
        brandName,
        is === "primary" && "dark:text-white dark:hover:text-primary",
        is === "secondary" && "dark:text-dark dark:hover:text-secondary",
        color === "light" && "text-light",
        color === "dark" && "text-dark",
        className
      )}
      {...(rest as TextProps)}
    >
      {children ? (
        children
      ) : isShort ? (
        name
      ) : (
        <>
          {name}
          <span className={brandNameTitle}>{title}</span>
        </>
      )}
    </Text>
  );
};
