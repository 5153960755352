import React, { FC } from "react";
import clsx from "clsx";

import { SplitSection, SplitSectionProps } from "components";

import {
  pageSectionContainer,
  pageSectionLeft,
  pageSectionRight,
} from "./PageSection.module.scss";

export const PageSection: FC<SplitSectionProps> = ({
  children,
  className = "bg-primary text-default",
  container,
  isResponsive = true,
  isReversed = false,
  left,
  leftProps,
  right,
  rightProps,
  ...rest
}) => (
  <SplitSection
    className={className}
    container={{ className: clsx(pageSectionContainer, container?.className) }}
    isResponsive={isResponsive}
    isReversed={isReversed}
    left={left}
    leftProps={{ className: clsx(pageSectionLeft, leftProps?.className) }}
    right={right}
    rightProps={{ className: clsx(pageSectionRight, rightProps?.className) }}
    {...(rest as SplitSectionProps)}
  >
    {children}
  </SplitSection>
);
