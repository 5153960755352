import { useStaticQuery, graphql } from "gatsby";

import { SiteMetadata } from "types";

export const siteMetadataFragment = graphql`
  fragment SiteMetadataFragment on Site {
    siteMetadata {
      address {
        locality
        region
        street
        zipcode
      }
      copyright {
        devMessage
        # message
        year
      }
      developer {
        email
        name
        url
      }
      description
      # footnote
      # hours
      lang
      name
      organization {
        email
        name
        # telephone
        url
      }
      siteUrl
      socialMedia {
        # facebook
        # instagram
        # linkedin
        twitter
        # yelp
      }
      tagPrefix
      title
    }
  }
`;

export const useSiteMetadataQuery = () => {
  const {
    site: { siteMetadata },
  }: SiteMetadata = useStaticQuery(
    graphql`
      query SiteMetadataQuery {
        site {
          ...SiteMetadataFragment
        }
      }
    `
  );

  return siteMetadata;
};
