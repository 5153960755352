import { useStaticQuery, graphql } from "gatsby";

import { AirtablePressData } from "graphql";

export interface RecentPressQuery {
  recentPress: {
    nodes: AirtablePressData[];
  };
}

export const useAirtableRecentPressQuery = () => {
  const { recentPress }: RecentPressQuery = useStaticQuery(graphql`
    query AirtableRecentPressQuery {
      recentPress: allAirtable(
        filter: {
          data: { published: { eq: true } }
          table: { eq: "AuthenTech Press" }
        }
        limit: 10
        sort: { order: DESC, fields: data___createdDate }
      ) {
        nodes {
          ...AirtablePressDataFragment
        }
      }
    }
  `);

  return recentPress;
};
