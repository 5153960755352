import React, { FC } from "react";
import clsx from "clsx";

import {
  FeaturedCTA,
  FeaturedCTAProps,
  Hero,
  HeroProps,
  HeroPattern,
  Link,
} from "components";

export interface FeaturedHeroProps extends Omit<HeroProps, "is"> {
  featured?: FeaturedCTAProps;
  hero?: HeroPattern;
  subtitle?: string;
  url?: string;
}

export const FeaturedHero: FC<FeaturedHeroProps> = ({
  caption,
  children,
  className,
  container,
  featured,
  hero = "page",
  subtitle,
  url,
  vh = "full",
  ...rest
}) => (
  <Hero
    caption={{
      ...caption,
      className: clsx(
        "flex flex-col flex-nowrap justify-center ml-0 mt-12 md:mt-14 lg:mt-16 xl:mt-18",
        caption?.className
      ),
      headingProps: {
        ...caption?.headingProps,
      },
      metaProps: {
        ...caption?.metaProps,
        as: Link,
        to: url,
        className: clsx(
          "no-underline hover:no-underline text-primary hover:text-secondary",
          caption?.metaProps?.className
        ),
      },
    }}
    container={{
      className: clsx(
        "flex flex-1 flex-col flex-nowrap justify-center",
        container?.className
      ),
      ...container,
    }}
    is={hero}
    vh={vh}
    {...(rest as HeroProps)}
    className={clsx(
      "flex flex-col flex-nowrap items-end overflow-initital z-10",
      className
    )}
  >
    {children}
    <FeaturedCTA
      {...featured}
      className={clsx("text-accent", featured?.className)}
    />
  </Hero>
);
