import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import {
  BoxPrimitive as Box,
  BoxProps,
  ButtonLink,
  ButtonLinkProps,
  Media,
  MediaProps,
  TextPrimitive as Text,
  TextProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";

import {
  featuredCTA,
  featuredCTAMedia,
  featuredCTAContent,
  featuredCTAHeading,
} from "./FeaturedCTA.module.scss";

export interface FeaturedCTAProps extends Omit<BoxProps, "media"> {
  attribute?: Element | ReactNode;
  button?: ButtonLinkProps;
  content?: Element | ReactNode;
  contentProps?: WrapperProps;
  cta?: BoxProps;
  footnote?: Element | ReactNode;
  heading?: string;
  headingProps?: TextProps;
  media?: MediaProps;
  mediaProps?: WrapperProps;
  to?: string;
}

export const FeaturedCTA: FC<FeaturedCTAProps> = ({
  attribute,
  button,
  children,
  className,
  content,
  contentProps,
  footnote,
  heading,
  headingProps,
  media,
  mediaProps,
  to,
  ...rest
}) => (
  <Box {...(rest as BoxProps)} className={clsx(featuredCTA, className)}>
    {media && (
      <Wrapper
        {...mediaProps}
        className={clsx(featuredCTAMedia, mediaProps?.className)}
      >
        {to ? (
          <ButtonLink is="wrapper" className={"w-full"} to={to}>
            <Media {...(media as MediaProps)} />
          </ButtonLink>
        ) : (
          <Media {...(media as MediaProps)} />
        )}
        {attribute}
      </Wrapper>
    )}
    {children}
    <Wrapper
      {...contentProps}
      className={clsx(featuredCTAContent, contentProps?.className)}
    >
      {heading && (
        <Text
          as="h2"
          is="subheading"
          {...headingProps}
          className={clsx(featuredCTAHeading, headingProps?.className)}
        >
          {heading}
        </Text>
      )}
      {content}
      {button && to && (
        <ButtonLink {...(button as ButtonLinkProps)} to={to}>
          {button.label}
        </ButtonLink>
      )}
      {footnote}
    </Wrapper>
  </Box>
);
