import React, { FC } from "react";
import clsx from "clsx";

import {
  CardPrimitive as Card,
  CardProps,
  TextPrimitive as Text,
  TextProps,
} from "components";
import HandshakeIcon from "icons/handshake-icon.svg";
import InvestorIcon from "icons/investor-icon.svg";
import NetworkingIcon from "icons/networking-icon.svg";

import { iconCard, iconCardIcon, iconCardTitle } from "./IconCard.module.scss";

export interface IconCardProps extends CardProps {
  description?: string;
  descriptionProps?: TextProps;
  title?: string;
  titleProps?: TextProps;
}

export const renderCardIcon = (title: string | undefined) => {
  switch (title) {
    case "Community":
      return <NetworkingIcon className={iconCardIcon} />;
    case "Intention":
      return <InvestorIcon className={iconCardIcon} />;
    case "Value":
      return <HandshakeIcon className={iconCardIcon} />;
    default:
      return null;
  }
};

export const IconCard: FC<IconCardProps> = ({
  children,
  className,
  description,
  descriptionProps,
  title,
  titleProps,
  ...rest
}) => (
  <Card className={clsx(iconCard, className)} {...(rest as CardProps)}>
    {title && (
      <>
        {renderCardIcon(title)}
        <Text
          as="h4"
          is="subtitle"
          {...titleProps}
          className={clsx(iconCardTitle, titleProps?.className)}
        >
          {title}
        </Text>
      </>
    )}
    {description && (
      <Text as="p" {...descriptionProps}>
        {description}
      </Text>
    )}
    {children}
  </Card>
);
