import React, { FC } from "react";
import clsx from "clsx";

import {
  ButtonProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";
import { BrandProps, HeaderMenuProps } from "containers";
import { GatsbyLocation } from "types";
import { useTheme } from "contexts";

import {
  LayoutFooter,
  LayoutFooterProps,
  LayoutHeader,
  LayoutHeaderProps,
  LayoutMain,
  LayoutMainProps,
} from "./components";

export interface DefaultLayoutProps extends WrapperProps {
  brand?: BrandProps;
  footer?: LayoutFooterProps;
  header?: LayoutHeaderProps;
  isMDX?: boolean;
  location?: GatsbyLocation;
  main?: LayoutMainProps;
  menu?: HeaderMenuProps;
  themeSwitch?: ButtonProps;
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({
  brand,
  children,
  className,
  footer,
  header,
  isMDX,
  location,
  main,
  menu,
  themeSwitch,
  ...rest
}) => {
  const { theme } = useTheme();

  return (
    <Wrapper
      as="div"
      {...(rest as WrapperProps)}
      className={clsx("min-h-screen", theme, className)}
    >
      <LayoutHeader
        brand={brand}
        location={location}
        menu={menu}
        themeSwitch={themeSwitch}
        {...header}
      />
      <LayoutMain {...main} isMDX={isMDX}>
        {children}
      </LayoutMain>
      <LayoutFooter location={location} {...footer} />
    </Wrapper>
  );
};
