import React, { FC } from "react";
import clsx from "clsx";

import { Box, Card, Link, Section, SectionProps, Text } from "components";
import { useAirtableCompaniesQuery } from "graphql";
import { limitText } from "utils";

import {
  companiesGrid,
  companiesLink,
  companiesName,
  companiesSection,
} from "./Companies.module.scss";

export interface CompaniesProps extends SectionProps {
  heading?: string;
}

export const Companies: FC<CompaniesProps> = ({
  children,
  className,
  heading,
}) => {
  const { nodes } = useAirtableCompaniesQuery();

  return (
    <Section
      className={clsx(companiesSection, className)}
      caption={{ heading }}
    >
      {nodes?.length && (
        <Box className={companiesGrid}>
          {nodes.map(({ data: { description, id, name, website } }) =>
            website ? (
              <Link
                to={website}
                rel="noopener"
                target="_blank"
                className={companiesLink}
                key={id}
              >
                {name && (
                  <Text className={companiesName} as="h4" is="subheading">
                    {name}
                  </Text>
                )}
                {description && <Text as="p">{limitText(description)}</Text>}
              </Link>
            ) : (
              <Card key={id}>
                {name && (
                  <Text className={companiesName} as="h4" is="subheading">
                    {name}
                  </Text>
                )}
                {description && <Text as="p">{limitText(description)}</Text>}
              </Card>
            )
          )}
        </Box>
      )}
      {children}
    </Section>
  );
};
