import React, { FC } from "react";
import clsx from "clsx";
import { CgCopyright } from "react-icons/cg";

import {
  Link,
  LinkProps,
  TextPrimitive as Text,
  TextProps,
  WrapperPrimitive as Wrapper,
  WrapperProps,
} from "components";
import { useSiteMetadataQuery } from "graphql";

export interface CopyrightProps extends WrapperProps {
  showCopyright?: boolean;
  showDeveloper?: boolean;
  showFootnote?: boolean;
  showMessage?: boolean;
  showOrg?: boolean;
  text?: TextProps;
}

export const CopyrightLink: FC<LinkProps> = ({
  children,
  className,
  text = "caption",
  ...rest
}) => (
  <Link
    is="primary"
    text={text}
    {...(rest as LinkProps)}
    className={clsx(
      "no-underline hover:underline dark:text-white dark:hover:text-primary",
      className
    )}
  >
    {children}
  </Link>
);

export const Copyright: FC<CopyrightProps> = ({
  className,
  showCopyright = false,
  showDeveloper = true,
  showFootnote = false,
  showMessage = false,
  showOrg = false,
  text,
  ...rest
}) => {
  const { developer, copyright, footnote, organization } =
    useSiteMetadataQuery();
  const currentYear = new Date().getFullYear();

  const textProps: TextProps = {
    as: "p",
    is: "caption",
    className: "dark:text-white",
    ...text,
  };

  return (
    <Wrapper as="div" className={className} {...(rest as WrapperProps)}>
      <Text {...textProps}>
        {showCopyright && (
          <>
            <CgCopyright className="inline-flex mr-1" />
            {` ${copyright?.year || currentYear}`},
          </>
        )}
        {showMessage && copyright?.message && ` ${copyright.message} `}
        {showOrg && organization?.name && (
          <>
            <CopyrightLink
              to={organization.url || `mailto:${organization.email}`}
            >
              {organization.name}
            </CopyrightLink>
            .
          </>
        )}
        {showDeveloper && copyright?.devMessage && ` ${copyright.devMessage} `}
        {showDeveloper && developer?.name && (
          <>
            <CopyrightLink
              to={developer.url || `mailto:${developer.email}`}
              rel="noopener"
              target="_blank"
            >
              {developer.name}
            </CopyrightLink>
          </>
        )}
      </Text>
      {showFootnote && footnote && (
        <Text as="p" {...textProps}>
          {footnote}
        </Text>
      )}
    </Wrapper>
  );
};
