import React, { FC } from "react";
import clsx from "clsx";

import { Box, Card, Link, Section, SectionProps, Text } from "components";
import { useAirtableRecentCompaniesQuery } from "graphql";

import {
  recentCompaniesGrid,
  recentCompaniesLink,
  recentCompaniesName,
  recentCompaniesSection,
} from "./RecentCompanies.module.scss";

export interface RecentCompaniesProps extends SectionProps {
  heading?: string;
}

export const RecentCompanies: FC<RecentCompaniesProps> = ({
  children,
  className,
  heading,
}) => {
  const { nodes } = useAirtableRecentCompaniesQuery();

  const limitText = (text: string, limit: number | undefined = 150): string => {
    if (!text || !limit || text.length <= limit) {
      return text;
    }

    let limitted = text.slice(0, limit).trim();
    const index = limitted.lastIndexOf(" ");
    if (index !== -1) {
      limitted = limitted.slice(0, index);
    }
    limitted = limitted.replace(/[.,]*$/g, "");

    return `${limitted}...`;
  };

  return (
    <Section
      className={clsx(recentCompaniesSection, className)}
      caption={{ heading }}
    >
      {nodes?.length && (
        <Box className={recentCompaniesGrid}>
          {nodes.map(({ data: { description, id, name, website } }) =>
            website ? (
              <Link
                to={website}
                rel="noopener"
                target="_blank"
                className={recentCompaniesLink}
                key={id}
              >
                {name && (
                  <Text className={recentCompaniesName} as="h4" is="subheading">
                    {name}
                  </Text>
                )}
                {description && <Text as="p">{limitText(description)}</Text>}
              </Link>
            ) : (
              <Card key={id}>
                {name && (
                  <Text className={recentCompaniesName} as="h4" is="subheading">
                    {name}
                  </Text>
                )}
                {description && <Text as="p">{limitText(description)}</Text>}
              </Card>
            )
          )}
        </Box>
      )}
      {children}
    </Section>
  );
};
