import React, { FC, forwardRef } from "react";
import clsx from "clsx";

import { BoxColor, BoxProps } from "./types";

export const boxColor = (color: BoxColor): string => {
  switch (color) {
    case "dark":
      return "bg-dark text-light";
    case "light":
      return "bg-light text-dark";
    case "primary":
      return "bg-primary text-primary";
    case "secondary":
      return "bg-secondary text-secondary";
    case "transparent":
      return "bg-transparent border-transparent";
    default:
      return "bg-default text-default";
  }
};

export const BoxPrimitive: FC<BoxProps> = <
  E extends HTMLElement = HTMLDivElement
>({
  as = "div",
  children,
  className,
  color,
  innerRef,
  position,
  ...rest
}: BoxProps<E>) => {
  const Component = as;

  return (
    <Component
      {...rest}
      className={clsx(color && boxColor(color), className)}
      position={position}
      ref={innerRef}
    >
      {children}
    </Component>
  );
};

export const Box = forwardRef<HTMLDivElement, BoxProps<HTMLDivElement>>(
  (props, ref) => <BoxPrimitive {...props} innerRef={ref} />
);
