// text utils

export const limitText = (
  text: string,
  limit: number | undefined = 150
): string => {
  if (!text || !limit || text.length <= limit) {
    return text;
  }
  let limitted = text.slice(0, limit).trim();
  const index = limitted.lastIndexOf(" ");

  if (index !== -1) {
    limitted = limitted.slice(0, index);
  }
  limitted = limitted.replace(/[.,]*$/g, "");

  return `${limitted}...`;
};
