import { useStaticQuery, graphql } from "gatsby";

import { GatsbyImageProps } from "types";

export interface BrandQuery {
  logo: GatsbyImageProps;
  logoDark: GatsbyImageProps;
  logoLight: GatsbyImageProps;
  site: {
    siteMetadata: {
      name?: string;
      title?: string;
    };
  };
}

export const brandLogoMediaFragment = graphql`
  fragment BrandLogoMediaFragment on File {
    childImageSharp {
      gatsbyImageData(
        formats: [AUTO, WEBP, AVIF]
        layout: CONSTRAINED
        placeholder: BLURRED
        quality: 90
      )
    }
    id
    name
  }
`;

export const brandSiteMetadataFragment = graphql`
  fragment BrandSiteMetadataFragment on Site {
    siteMetadata {
      name
      title
    }
  }
`;

export const useBrandQuery = () => {
  const {
    logo,
    logoDark,
    logoLight,
    site: {
      siteMetadata: { name, title },
    },
  }: BrandQuery = useStaticQuery(
    graphql`
      query BrandQuery {
        logo: file(relativePath: { eq: "logo.png" }) {
          ...BrandLogoMediaFragment
        }
        logoDark: file(relativePath: { eq: "logo-dark.png" }) {
          ...BrandLogoMediaFragment
        }
        logoWhite: file(relativePath: { eq: "logo-light.png" }) {
          ...BrandLogoMediaFragment
        }
        site {
          ...SiteMetadataFragment
        }
      }
    `
  );

  return {
    logo,
    logoDark,
    logoLight,
    name,
    title,
  };
};
