import React, { FC } from "react";
import clsx from "clsx";

import { Section, SectionProps } from "components";
import { BrandProps } from "containers";
import {
  LayoutFooter,
  LayoutFooterProps,
  LayoutHeader,
  LayoutHeaderProps,
  LayoutMain,
  LayoutMainProps,
} from "layouts";
import { GatsbyLocation } from "types";

import {
  panelLayoutContainer,
  panelLayoutWrapper,
} from "./PanelLayout.module.scss";

export interface PanelLayoutProps
  extends Omit<SectionProps, "footer" | "header"> {
  brand?: BrandProps;
  footer?: LayoutFooterProps;
  header?: LayoutHeaderProps;
  isMDX?: boolean;
  location?: GatsbyLocation;
  main?: LayoutMainProps;
}

export const PanelLayout: FC<PanelLayoutProps> = ({
  brand,
  children,
  className,
  container,
  footer,
  header,
  is = "layout",
  isMDX,
  location,
  main,
  ...rest
}) => (
  <Section
    as="div"
    is={is}
    color="secondary"
    {...(rest as SectionProps)}
    container={{
      as: "div",
      is: "panel",
      ...container,
      className: clsx(
        "bg-default text-default xs:shadow-md",
        panelLayoutContainer,
        container?.className
      ),
    }}
    className={clsx(panelLayoutWrapper, className)}
  >
    <LayoutHeader
      location={location}
      showMenu={false}
      {...header}
      className={clsx("bg-transparent", header?.className)}
    />
    <LayoutMain {...main} isMDX={isMDX}>
      {children}
    </LayoutMain>
    <LayoutFooter location={location} mod="transparent" {...footer} />
  </Section>
);
