import React, { FC } from "react";
import clsx from "clsx";

import { BoxPrimitive as Box, BoxProps, Link, LinkProps } from "components";
import { useTheme } from "contexts";

import {
  BrandLogo,
  BrandLogoProps,
  BrandName,
  BrandNameProps,
} from "./components";
import { BrandColor } from "./types";

import { brandContainer } from "./Brand.module.scss";

export type BrandPattern = "primary" | "secondary";

export interface BrandProps extends Omit<BoxProps, "color" | "name"> {
  color?: BrandColor;
  is?: BrandPattern;
  isShort?: boolean;
  linkTo?: string;
  logo?: BrandLogoProps;
  name?: BrandNameProps;
  showLink?: boolean;
  showLogo?: boolean;
  showName?: boolean;
}

export const Brand: FC<BrandProps> = ({
  children,
  className,
  color,
  is,
  isShort = true,
  linkTo = "/",
  logo,
  name,
  showLink = true,
  showLogo = false,
  showName = true,
  ...rest
}) => {
  const { theme } = useTheme();

  const renderBrand = () => (
    <>
      {showLogo && (
        <BrandLogo is={is} color={color ? color : theme} {...logo} />
      )}
      {showName && (
        <BrandName is={is} color={color} isShort={isShort} {...name}>
          {children}
        </BrandName>
      )}
    </>
  );

  return (
    <>
      {showLink ? (
        <Link
          {...(rest as LinkProps)}
          className={clsx(brandContainer, className)}
          to={linkTo}
        >
          {renderBrand()}
        </Link>
      ) : (
        <Box
          {...(rest as BoxProps)}
          className={clsx(brandContainer, className)}
        >
          {renderBrand()}
        </Box>
      )}
    </>
  );
};
